import tickRecord from ".././../assets/Svg/tickRecord.svg";

export const record = [
  {
    id: 0,
    img: tickRecord,
  },
  {
    id: 1,
    img: tickRecord,
  },
  {
    id: 2,
    img: tickRecord,
  },
  {
    id: 3,
    img: tickRecord,
  },
  {
    id: 4,
    img: tickRecord,
  },
];

export const training = {
  train: `Training`,
  rec: ` Record`,
  deo: `Name: `,
  john: ` John Doe`,
  employee: `Employer: `,
  corporate: ` Corporate User Name`,
  course: `Training Course:`,
  title: `Syllabus Title:`,
  general: `General Security Awareness Training (GSAT)`,
  module: `Module Completed`,
  date: `Date:`,
  expiry: `Expiry Date:`,
  threat: `The Threat to Aviation`,
  security: `Security in the Aviation Industry`,
  role: `The Trainee’s Role`,
  access: `Access Control and the Screening Point`,
  visit: `Escorting employment and/or visitor passholders`,
  issue: `Certificate Issued:`,
  percent: `98%`,
  instruct: `Instructor signature:`,
  ltd: `Training Provider: AVTAV LTD #182`,
};
