import { useState } from "react";
import {
  examPostFail,
  examPostSuccess,
  examSuccess,
} from "../redux/slices/examSlice";
import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";

const useExamFormHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getExamList = async (slug) => {
    setLoading(true);
    try {
      const response = await HttpApis.getExamQuesList(slug);
      dispatch(examSuccess(response?.data?.data));
      if (response?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      console.error("error");
      return null;
    }
  };

  const postExamListAction = (slug, param) => async (dispatch) => {
    try {
      const res = await HttpApis.postExamQuesList(slug, param);
      dispatch(examPostSuccess(res));
      return res;
    } catch (error) {
      dispatch(examPostFail(error?.message));
      return null;
    }
  };

  const postExamList = async (slug, param) => {
    const res = await dispatch(postExamListAction(slug, param));
    return res;
  };

  return {
    getExamList,
    postExamList,
    loading,
  };
};

export default useExamFormHook;
