export const constant = {
  checkout: "CheckOut",
  login: "Login",
  Cancel: "Cancel",
  Next: "Next",
  Previous: "Prev",
  register: "Register",
  SignUp: "Sign Up",
  AlreadyHaveAnAccount: "Already have an account? ",
  SignIn: "Sign In",
  CorporateUser: "Corporate User",
  AboutYou: "About you.",
  SidebarTitle: "TRAINING, VETTING AND RECRUITMENT",
  CourseName: "Course Name",
  Pricing: "Pricing",
  OriginalPrice: "Original Price",
  DiscountedPrice: "Discounted Price",
  InvitedHeading: "You have been invited by AVTAV to take the below courses",
  Work: "About the company you work",
  Password: " Your password",
  Declaration: "Corporate Declaration Form",
  SignUpDeclaration: "Corporate Declaration Form",
  Text: "Some text here can do",
  Request:
    "Your request has been submitted, you will receive an email with further instructions",
  Sign: "Please fill in and sign",
  Module_list_item: "Valid background check.",
  Module_Requirements: "Requirements",
  Modules_included: "Modules included",
  Module_General: "General Security Awareness Training (GSAT)",
  Notice_bar: " Want to train more than one person?",
  Button_title: "Try AVTAV Business",
  Alert_text: "Want to train more than one person",
  Try: "Try",
  StarText: "1,2k",
  Link_text: "AVTAV",
  BusinessText: "Business",
  SingleUser: "Single User",
  Info: "Your employment information",
  Confirm: "I confirm that I have undergone a full 5-year background check.",
  Industry:
    "I am a UK based employee, undertaking a role in the aviation industry. ",
  SelfSole: "I am a sole trader making a self-declaration.",
  EmpButton: "Send my employer the declaration form",
  Sole: "Sole Trader | Declaration Form",
  Register: "Register Account",
  InvitedUser: "Invited User",
  Related_Courses: "Related Courses",
  Regulated_text: "Regulated Agent Courses",
  Enroll_button: "Enroll now",
  Add_to_cart_button: "Add to cart",
  Go_to_course: "Go to course",
  Card_section_text: "General Security Awareness Training (GSAT)",
  card_number: "7.00",
  ParaText:
    "This course is for those who require access to Security Restricted Areas (SRA) and will be issued with an airside staff pass.<br/> If other superseding security training courses have been completed, staff may be exempt from requiring GSAT",
  About: "About This Course",
  BrowseText: "Browse Course Information",
  Avtav: "AVTAV Trainee",
  Login: "Login",
  NextStep: "Login",
  Enroll: "You need an account to enroll or add courses to your cart.",
  Account: "Register an account first",
  Already: "Already have an account?",
  Single: "Sign In",
  Multi: "Business User Sign In",
  SingleUserAccount: "Single User Account",
  MultiUser: "Multi User (Corporate) Account",
  SingleLine:
    "Some explanation of what a single user account is and who it is for may come here",
  Find: "Find out more",
  AvtavT: "AVTAV Trainee",
  star: "*",
  CorporateImage: "CorporateImage",
  FormDeclaration: "The declaration form has been sent to your employer.",
  Once: "Once filled, signed and sent back to us. We will activate your account",
  DontHaveAccount: "Don't Have an Account?",
  Employer: `If you are employed in the aviation sector, we will require confirmation from your employer to verify this information and confirm they have conducted a background check on you. You will need to provide us with your employer details at the next stage and the background check will be re-directed to them. `,
};
export const DeclarationConstant = {
  Submit: "Submit",
  Sign: "Sign here",
};
export const HeaderConstant = {
  logoheader: "Business",
  NavBtn: ["About", "Training"],

  login: "Login",
  Createaccount: "Create account",
};

export const createAccountconstant = {
  logoheader: "Business",
  bannerdisc1: "The right solution for",
  bannerdisc2: "training your",
  bannerdiscspan: "aviation team.",
  bannerbutton: "Create business acoount",
  child2heading: "Advantages of using our platform",
  child2li: [
    "Specialist provision of training, specifically designed to cover your business need",
    "Competitive pricing on bulk license purchases and access to exclusive discounts",
    "A range of learning styles being delivered to suit everyone’s learning requirements.",
  ],
  btnalt: "btnarrow",
  banneralt: "motivational banner",
};

export const Checkout = {
  gsat: " General Security Awareness Training (GSAT) ",
  name: "Course name",
  title: "Checkout",
  Order_Details: "Order Details",
  selectBill: "Select Billing Address",
  Billing_Details: "Billing Details",
  Additional_info: "Additional Information",
  Order_notes: "Order notes (optional)",
  Buy_course: "Buy course",
  First_name: "First name",
  Last_name: "Last name",
  Email_address: "Email address",
  Phone: "Phone",
  Company_name: "Company name",
  Country_Region: "Country / Region",
  State_County: "State / County",
  Town_City: "Town / City",
  Street_address: "Street address",
  Postcode_ZIP: "Postcode / ZIP",
  Purchase_summary: "Purchase summary",
  Subtotal: "Subtotal",
  Number: " 7.00",
  Total: "Total",
  Payment_method: "Payment method",
  Text: "By completing your purchase you agree to these",
  Terms: "Terms of Service.",
  q: "Qty",
  x: "x1500",
  saveBilling: `Save this billing address for future use.`,
  price: "Price (per license):",
  info: "Additional Information",
  notes: "Order notes ",
  option: "(optional)",
  vat: "VAT",
};

export const users = {
  Course: "Your courses 2",
  List: "A list of the courses you’re enrolled in",
  All: "All courses (2)",
  Fav: "Favourites",
};
