import styled from "styled-components";
import { Modal, Pagination, Progress } from "antd";
import { color } from "../../../theme/Color";

export const Courses = styled.div`
  .about {
    color: ${color._16384e};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
  }
  .assess {
    color: ${color._0f2a3c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 91%;
  }
  .online {
    color: ${color._0f2a3c};
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 91%;
  }
  .vali {
    color: ${color._0f2a3c};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 91%;
  }
  .asessVali {
    margin-top: 28px;
    display: flex;
    gap: 5rem;
  }
`;
export const ModalContainer = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 0px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
export const Line = styled.div`
  height: 0.5px;
  background: ${color._d6d6d6};
  margin: 30px 0px;
`;
export const Gsat = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 2%;
  .security {
    color: ${color._656565};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 515px;
  }
  .general {
    color: ${color._656565};
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const Rating = styled.div`
  display: flex;
  gap: 8%;
  align-items: center;
  margin-top: 30px;
  width: 596px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 0.3px solid rgba(21, 55, 77, 0.28);
  background: rgba(241, 251, 255, 0.89);

  .ratings {
    display: flex;
    gap: 9px;
    align-items: center;
    display: flex;
    color: ${color._000000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 91%;
  }
  .eng {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
    display: flex;
    gap: 4px;
  }
  .hrs {
    color: ${color._16384e};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 4px;
  }
  .rate {
    color: ${color._15374d};

    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 91%;
  }
`;

export const Certify = styled.div`
  .cert {
    color: ${color._16384e};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }
  .grey {
    height: 67px;
    flex-shrink: 0;
    border-radius: 3px;
    background: ${color._f7f7f7};
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 15px;
  }
  .comp {
    color: ${color._656565};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const Review = styled.div`
  .rateIndex {
    margin-top: 18px;
  }
  .rates10 {
    color: ${color._000000};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 91%;
  }
  .rates5 {
    color: ${color._000000};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 91%;
    margin: 8px 0px;
  }
  .cert {
    color: ${color._16384e};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }
`;
export const Group = styled.div`
  .jdPage {
    display: flex;
    gap: 21px;
    margin-top: 30px;
  }
  .pageJohn {
    width: 550px;
  }
  .jd {
    color: ${color._ffffff};
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 91%;
    background: ${color._0f2a3c};
    border-radius: 50px;
    width: 60px;
    height: 60px;
  }
  .page {
    color: ${color._767676};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 161%;
    margin-top: 8px;
  }
  .john {
    color: ${color._68828e};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 91%;
    margin-bottom: 8px;
  }
  .week {
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 161%;
  }
`;
export const LeaveRating = styled.div`
  display: flex;
  justify-content: space-between;
  .btn {
    display: inline-flex;
    height: 32px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 13px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._177a9c};
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 56.5%;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
export const Data = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: ${color._0f2a3c};
`;
export const Cart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Module = styled.div`
  display: flex;
  width: 100%;
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
`;
export const Training = styled.div`
  width: 75%;
  .faangle {
    margin-right: 10px;
  }
  .slash {
    color: ${color._5193aa};
  }
  .videoCourse {
    // position: relative;
  }
  .complete {
    color: ${color._fcfcfc};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
  }
  .progressBar {
    display: flex;
    gap: 26px;
    margin-top: 15px;
    // position: relative;
  }
  .rev {
    height: 32px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 2px;
    background: rgba(23, 122, 156, 0.36);
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 56.5%;
    display: flex;
    justify-content: space-between;
    border: none;
  }
  .secure {
    background: ${color._15374d};
    height: 60px;
    color: ${color._ffffff};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 34px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const TickData = styled.div`
  width: 25%;
  .maincourse {
    background: var(--Question-Color, ${color._007097});
    height: 93px;
  }
  .mod {
    color: ${color._ffffff};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .mainModule {
    color: ${color._f5f5f5};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .cancel {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .maincourse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
`;
export const Aims = styled.div``;
export const MainModule = styled.div`
  .examscheck {
    height: 61px;
    flex-shrink: 0;
    background: #f3fbfe;
  }
  .ex {
    color: var(--Question-Color, #007097);
    font-size: 15px;
    padding: 22px 0px 0px 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  &.accordion-button::after {
    width: 12px;
    height: 6px;
  }
`;
export const Mods = styled.div`
  .ant-checkbox .ant-checkbox-input {
    opacity: unset;
  }
  .mod1 {
    color: ${color._007097};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .mins {
    color: ${color._000000};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .min15 {
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;
export const AimsBody = styled.div`
  .ant-radio-wrapper .ant-radio-input {
    opacity: unset;
  }
  .accordion-button {
    background: ${color._ffffff};
  }
  .aimsLevel {
    margin-top: 25px;
  }
  .body {
    color: #000000;
    background: ${color._fbfeff};
    display: flex;
    padding: 5px 0px;
    align-items: flex-start;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sum {
    color: #000;
    background: ${color._fbfeff};
    display: flex;
    padding: 5px 0px;
    align-items: flex-start;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .quiz {
    color: ${color._177a9c};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    background: ${color._fbfeff};
    display: flex;
    padding: 5px 0px;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
`;
export const FilterRate = styled.div`
  .filterRate {
    color: #15374d;
    font-weight: 600;
    font-size: 15px;
  }
  display: flex;
  gap: 23px;
  // position: relative;
  // top: 28px;
  // right: 15px;
  align-items: center;
  select {
    padding: 0px 10px;
    font-size: 16px;
    width: 173px;
    height: 44px;
    border-radius: 3px;
    background: ${color._f7f7f7};
    color: ${color._333};
    border: none;
    outline: none;
  }
`;
export const ProgressMain = styled(Progress)`
  &.ant-progress-line {
    width: 214px;
  }
`;
export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 200px;
`;
export const Pagin = styled(Pagination)`
  &.ant-pagination .ant-pagination-item-active {
    background-color: #082429 !important;
    color: ${color._ffffff} !important;
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    border-color: #082429 !important;
  }

  &.ant-pagination .ant-pagination-item {
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${color._D9D9D9};
    border-radius: 2px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    border-color: white;
  }
  &.ant-pagination .ant-pagination-item a {
    padding: 6px 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
  }
  &.ant-pagination .ant-pagination-disabled {
    color: ${color._858585} !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 17px;
    line-height: 95%;
  }
  &.ant-pagination {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  &.ant-pagination .ant-pagination-next {
    color: ${color._000000};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;
    margin: 17px 0px 0px 26px;
  }
  &.ant-pagination .ant-pagination-prev {
    color: ${color._000000};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 95%;

    margin: 17px 26px 0px 0px;
  }
`;

export const Lined = styled.div`
  width: 211px;
  height: 0.5px;
  background: ${color._d6d6d6};
`;
export const DownIcon = styled.div``;
export const DropMenu = styled.div`
  width: 215px;
  height: 106px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._ffffff};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  top: 25%;
  position: absolute;
  left: 55.3%;
  z-index: 9999;
  .stepsof {
    padding: 14px;
    color: ${color._15374d};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .certify {
    padding: 14px;
    color: ${color._6d7d8b};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ModalItems = styled.div`
  .textareas {
    display: flex;
    justify-content: center;
  }
  .stars {
    text-align: center;
    margin-bottom: 10px;
  }
  .courses {
    text-align: center;
    margin-top: 16px;
  }
  .submit {
    width: 848px;
    display: flex;
    justify-content: end;
    margin: 13px auto;
    padding-bottom: 20px;
  }
  .modalrate {
    width: 936px;
    height: 87px;
    flex-shrink: 0;
    border-radius: 5px 5px 0px 0px;
    background: #16384e;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.21);
  }
  .ratings {
    display: inline-flex;
    height: 32px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: none;
    border-radius: 2px;
    background: ${color._177a9c};
    color: ${color._ffffff};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 56.5%;
  }
  .rate {
    padding: 30px;
    color: ${color._ffffff};
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .namecourse {
    color: ${color._177a9c};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .gsat {
    color: ${color._16384e};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const LineRating = styled.div`
  width: 842px;
  height: 0.5px;
  background: ${color._d6d6d6};
  margin: 10px auto;
`;
export const TextRating = styled.textarea`
  padding: 10px;
  width: 848px;
  height: 185px;
  flex-shrink: 0;
  background: ${color._f5fbfe};
  outline: none;
  border: none;
  resize: none;
  &::placeholder {
    color: ${color._16384e};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const DiagonalImage = styled.img`
  padding: 0px;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
`;

export const QuizButton = styled.button`
  margin-top: 30px;
  border: none;
  color: white;
  background: #15374d;
  border-radius: 2px;
  padding: 6px 10px;
`;
