import HttpApis from "../services/HttpApis";
import {
  Action,
  Courses,
  DeclarationPreview,
  ExpirationDate,
  InvoiceDownload,
  ProgressBar,
  RenderDate,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { completeCourseSuccess } from "../redux/slices/completeCourseSlice";
import { useEffect, useState } from "react";

export const useCompletedCourse = () => {
  const dispatch = useDispatch();
  const [certificatePreview, setCertificatePreview] = useState(false);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completeCount, setCompleteCount] = useState(0);
  const [incompleteCount, setIncompleteCount] = useState(0);
  const [downloadData, setDownloadData] = useState(null);
  const courseCount = useSelector(
    (state) => state?.completeCourseReducer?.CoursesCompleted?.data?.count
  );
  const completedDataFetched = useSelector(
    (state) =>
      state?.completeCourseReducer?.CoursesCompleted?.data?.trainingInProgress
  );

  const generateAndDownloadPDF = async (selector) => {
    const captureParent = document.querySelector(selector);
    captureParent.scrollTop = captureParent.scrollHeight;
    captureParent.style.opacity = 1;
    captureParent.style.height = "1px";
    captureParent.style.overflow = "auto";
    const capture = document.querySelector(`${selector} > div`);
    capture.style = {};
    var height = capture.offsetHeight;
    capture.style.width = `1000px`;
    capture.style.height = `${height}px`;
    html2canvas(capture).then((canvas) => {
      const doc = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("img/jpg");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("Certificate.pdf");
      captureParent.style.opacity = 0;
      captureParent.style.height = "1px";
      captureParent.style.overflow = "auto";
    });
  };

  useEffect(() => {
    if (downloadData) {
      setTimeout(() => {
        generateAndDownloadPDF(".certificate");
      }, 1000);
    }
  }, [downloadData]);

  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    trainee_name: "",
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: courseCount,
      current: 1,
      pageSize: 5,
    },
  });

  const handleSearch = (e) => {
    setSearch((prev) => ({ ...prev, trainee_name: e.target.value }));
  };

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleType = (e) => {
    setSortOrder(e.target.value);
  };

  const getCompleteCourseData = async (params) => {
    setLoading(true);
    try {
      const response = await HttpApis.getCompleteCourses(params);
      dispatch(completeCourseSuccess(response?.data));
      setCompleteCount(response?.data?.data?.completeCount);
      setIncompleteCount(response?.data?.data?.incompleteCount);
      if (response?.status === 200) {
        setLoading(false);
      }
      return response?.data;
    } catch (error) {
      setLoading(false);
      console.log("error");
    }
  };

  useEffect(() => {
    setTableParams({
      pagination: {
        total: courseCount,
        current: 1,
        pageSize: 5,
      },
    });
  }, [courseCount]);

  const completedCourse = [
    {
      label: "Completion Date",
      mappedkey: "createdAt",
      sorter: true,
      customRender: RenderDate,
    },
    {
      label: "Expiry Date",
      mappedkey: "createdAt",
      customRender: ExpirationDate,
    },
    {
      label: "Trainee Name",
      mappedkey: "trainee_name",
      customRender: Courses,
    },
    {
      label: "Course Name",
      mappedkey: "courseName",
      customRender: Courses,
    },
    {
      label: "Certification",
      customRender: DeclarationPreview,
      action: (row) => {
        setCertificatePreview(row);
      },
    },
    {
      label: "Download",
      customRender: InvoiceDownload,
      action: (row) => {
        setDownloadData(row);
      },
    },
  ];

  const inProgressCourse = [
    {
      label: "Date Added",
      mappedkey: "createdAt",
      customRender: RenderDate,
    },
    {
      label: "Trainee Name",
      mappedkey: "trainee_name",
      customRender: Courses,
    },

    {
      label: "Email",
      mappedkey: "email",
    },
    {
      label: "Course Progress",
      mappedkey: "course_progress",
      customRender: ProgressBar,
    },
    {
      label: "Action",
      mappedkey: "action",
      customRender: Action,
      action: (row) => setModal(row),
    },
  ];

  return {
    completedCourse,
    completedDataFetched,
    getCompleteCourseData,
    courseCount,
    loading,
    tableParams,
    sortOrder,
    search,
    handleSearch,
    handleType,
    handleTableChange,
    certificatePreview,
    setCertificatePreview,
    modal,
    inProgressCourse,
    setModal,
    downloadData,
    completeCount,
    incompleteCount,
  };
};
