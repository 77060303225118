import * as Styled from "./style";
import Accordion from "react-bootstrap/Accordion";
import React, { useEffect } from "react";
import { course } from "./data";

const SideBarPage = ({
  handleRadioChange,
  isExam = false,
  nextHandleExamRoute,
  formatedSliderData,
  setFormatedSliderData,
  formatted_data,
  moduleNameRef,
  showcheckbox = true,
  visitedModules,
  checkListData,
  moduleDurations,
}) => {
  useEffect(() => {
    formatted_data(null);
  }, [checkListData]);

  const audioModuleDuration = {
    "The Threat to Civil Aviation": 18,
    "Security In The Aviation Industry": 5,
    "The Trainee’s Role": 13,
    "Access Control and The Screening Point": 21,
    "Escorting Employment and Visitor Pass Holders": 5,
  };

  const handleCheckboxChange = (
    e,
    item,
    moduleName,
    childIndex,
    checkedValue
  ) => {
    const { checked } = e.target;
    if (checkedValue) {
      setFormatedSliderData((prevState) => {
        const updatedModule = prevState[moduleName]?.map((page, index) => {
          if (index === childIndex) {
            return { ...page, checkedValue: true };
          }
          return page;
        });
        return { ...prevState, [moduleName]: updatedModule };
      });
    } else {
      setFormatedSliderData((prevState) => {
        const updatedModule = prevState[moduleName]?.map((page, index) => {
          if (index === childIndex) {
            return { ...page, checkedValue: checked || false };
          }
          return page;
        });
        return { ...prevState, [moduleName]: updatedModule };
      });
    }

    handleRadioChange(e, item);
  };

  return (
    <Styled.MainModule>
      <div>
        {formatedSliderData &&
          Object.keys(formatedSliderData)?.map((item, index) => (
            <Accordion defaultActiveKey={null}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Styled.Mods>
                    <div className="mod1">{`${course.mod1} ${index + 1}`}</div>
                    <div className="mins">
                      {item}
                      <span className="min15">{` (  ${
                        audioModuleDuration[item] || 0
                      } ${course.min15})`}</span>
                    </div>
                  </Styled.Mods>
                </Accordion.Header>
                <Accordion.Body>
                  <Styled.AimsBody>
                    <div className="aimsLevel">
                      {formatedSliderData &&
                        formatedSliderData[item]?.map((i, childIndex) => {
                          return i?.pageType === "module" ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                cursor: showcheckbox
                                  ? "pointer"
                                  : "not-allowed",
                              }}>
                              {showcheckbox && (
                                <input
                                  type="checkbox"
                                  name="page"
                                  id={`checkbox-${index}-${childIndex}`}
                                  checked={
                                    visitedModules.current.includes(item) &&
                                    (i.checkedValue || false)
                                  }
                                  data-index={`${index}-${childIndex}`}
                                  onChange={
                                    visitedModules.current.includes(item) &&
                                    i.checkedValue
                                      ? (e) =>
                                          handleCheckboxChange(
                                            e,
                                            i,
                                            item,
                                            childIndex,
                                            i?.checkedValue
                                          )
                                      : () => {}
                                  }
                                />
                              )}
                              <label
                                for={`checkbox-${index}-${childIndex}`}
                                className="body">
                                {i?.name}
                              </label>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                              }}>
                              {showcheckbox && (
                                <input
                                  type="checkbox"
                                  id={`checkbox-${index}-${childIndex}`}
                                  name="quiz"
                                  style={{
                                    cursor: showcheckbox
                                      ? "pointer"
                                      : "not-allowed",
                                  }}
                                  checked={i?.checkedValue}
                                  data-index={`${index}-${childIndex}`}
                                  onChange={
                                    moduleNameRef?.current == item &&
                                    i?.checkedValue
                                      ? (e) =>
                                          handleCheckboxChange(
                                            e,
                                            i,
                                            item,
                                            childIndex,
                                            i?.checkedValue
                                          )
                                      : () => {}
                                  }
                                />
                              )}
                              <label
                                for={`checkbox-${index}-${childIndex}`}
                                className="quiz"
                                style={{
                                  cursor: showcheckbox
                                    ? "pointer"
                                    : "not-allowed",
                                }}>
                                {i?.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </Styled.AimsBody>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        {isExam && (
          <div
            className="examscheck"
            style={{ cursor: "pointer" }}
            onClick={nextHandleExamRoute}>
            <div className="ex">Exam</div>
          </div>
        )}
      </div>
    </Styled.MainModule>
  );
};

export default SideBarPage;
